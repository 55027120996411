<template>
  <a href="" class="menu-compose"><i class="ri-pencil-line"></i></a>
  <div class="compose d-none">
    <div class="compose-content">
      <div class="compose-header">
        <h6 class="compose-title">Novo E-mail</h6>
        <nav class="nav">
          <a href="#" class="nav-link nav-link-minimize"><i class="ri-subtract-line"></i></a>
          <a href="#" class="nav-link nav-link-fullscreen">
            <i class="ri-fullscreen-line"></i>
            <i class="ri-fullscreen-exit-line"></i>
          </a>
          <a href="#" class="nav-link nav-link-close"><i class="ri-close-line"></i></a>
        </nav>
      </div><!-- compose-header -->
      <div class="compose-body">
        <div class="form-group"><input type="text" v-model="email.to" class="form-control"
            placeholder="Digite o e-mail do contato">
        </div>
        <div class="form-group"><input type="text" v-model="email.subject" class="form-control"
            placeholder="Digite o assunto"></div>
        <div class="form-group"><textarea class="form-control" v-model="email.html" rows="10"></textarea></div>
      </div><!-- compose-body -->
      <!-- <nav class="nav nav-toolbar">
        <a href="" class="nav-link"><i class="ri-arrow-go-back-line"></i></a>
        <a href="" class="nav-link"><i class="ri-arrow-go-forward-line"></i></a>
        <span class="nav-separator"></span>
        <a href="" class="nav-link"><i class="ri-text"></i></a>
        <a href="" class="nav-link"><i class="ri-bold"></i></a>
        <a href="" class="nav-link"><i class="ri-italic"></i></a>
        <a href="" class="nav-link"><i class="ri-underline"></i></a>
        <a href="" class="nav-link"><i class="ri-align-left"></i></a>
        <a href="" class="nav-link"><i class="ri-list-check"></i></a>
        <span class="nav-separator"></span>
        <a href="" class="nav-link"><i class="ri-attachment-2"></i></a>
        <a href="" class="nav-link"><i class="ri-link"></i></a>
        <a href="" class="nav-link"><i class="ri-image-add-line"></i></a>
        <a href="" class="nav-link"><i class="ri-emotion-line"></i></a>
        <a href="" class="nav-link"><i class="ri-more-2-fill"></i></a>
      </nav>nav-toolbar -->
      <div class="compose-footer">
        <button @click="sendEmail()" type="submit" class="btn btn-primary btn-icontext"><i
            class="ri-send-plane-fill me-2"></i>Enviar</button>
        <button class="btn btn-white btn-icon"><i class="ri-delete-bin-line"></i></button>
      </div><!-- compose-footer -->
    </div><!-- compose-content -->
  </div><!-- compose -->
</template>

<script setup>

import { ref } from 'vue'
const axios = require('axios');

const email = ref({
  to: "",
  subject: "",
  html: ""
})

// Função para fazer a requisição axios
const sendEmail = async () => {
  try {
    const response = await axios.request({
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api-growth.flashvolve.com.br/emails',
      headers: {
        'Content-Type': 'application/json'
      },
      data: email.value
    });
    console.log(response.data);
    email.value.to = "";
    email.value.subject = "";
    email.value.html = "";
  } catch (error) {
    alert(error)
    console.log(error);
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-compose {
  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 800;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-color: #dc0f37;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: all 0.2s;
  box-shadow: 0 1px 1px rgba(204, 207, 217, 0.25), 0 2px 2px rgba(204, 207, 217, 0.2), 0 4px 4px rgba(204, 207, 217, 0.15), 0 8px 8px rgba(204, 207, 217, 0.1), 0 16px 16px rgba(204, 207, 217, 0.05);
}

@media (prefers-reduced-motion: reduce) {
  .menu-compose {
    transition: none;
  }
}

@media (min-width: 768px) {
  .menu-compose {
    right: 25px;
    bottom: 25px;
  }
}

.menu-compose:hover,
.menu-compose:focus {
  transform: scale(1.1);
  color: #fff;
}

.menu-compose i {
  font-size: 28px;
  font-weight: 400;
  line-height: 1;
}

.compose {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

@media (min-width: 768px) {
  .compose {
    padding: 20px 20px 0;
    max-height: 100vh;
    overflow-y: auto;
  }
}

.compose.minimize .compose-content {
  width: 280px;
}

.compose.minimize .compose-body,
.compose.minimize .nav-toolbar,
.compose.minimize .compose-footer {
  display: none;
}

.compose.fullscreen:not(.minimize) {
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(33, 35, 41, 0.5);
}

.compose.fullscreen:not(.minimize) .compose-content {
  width: 80%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0 1px 2px rgba(52, 55, 64, 0.07), 0 2px 4px rgba(52, 55, 64, 0.07), 0 4px 8px rgba(52, 55, 64, 0.07), 0 8px 16px rgba(52, 55, 64, 0.07), 0 16px 32px rgba(52, 55, 64, 0.07), 0 32px 64px rgba(52, 55, 64, 0.07);
}

.compose.fullscreen:not(.minimize) .nav-link-fullscreen i:first-child {
 
  display: none;
}

.compose.fullscreen:not(.minimize) .nav-link-fullscreen i:last-child {
  display: block;
}

.compose-content {
  position: relative; 
  width: 100vw;
  height: 75vh;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .compose-content {
    width: 498px;
    height: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0 1px 2px rgba(174, 177, 189, 0.07), 0 2px 4px rgba(174, 177, 189, 0.07), 0 4px 8px rgba(174, 177, 189, 0.07), 0 8px 16px rgba(174, 177, 189, 0.07), 0 16px 32px rgba(174, 177, 189, 0.07), 0 32px 64px rgba(174, 177, 189, 0.07);
  }
}

@media (min-width: 1200px) {
  .compose-content {
    width: 520px;
  }
}

.compose-header {
  height: 48px;
  padding: 0 15px;
  background-color: #dc0f37;
  color: #fff;
  display: flex;
  align-items: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  cursor: pointer;
}

.compose-header h6,
.compose-header .h6 {
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
}

.compose-header .nav {
  margin-top: 1.5px;
  margin-left: 10px;
  align-items: center;
}

.compose-header .nav-link {
  padding: 0;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.75);
  line-height: 1;
}

.compose-header .nav-link:not(:last-child) {
  display: none;
}

@media (min-width: 768px) {
  .compose-header .nav-link:not(:last-child) {
    display: block;
  }
}

.compose-header .nav-link+.nav-link {
  margin-left: 8px;
}

.compose-header .nav-link-fullscreen i:last-child {
  display: none;
}

.compose-body {
  flex: 1;
  background-color: #fff;
  border-bottom: 1px solid #f0f2f7;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.compose-body .form-group+.form-group {
  margin-top: 15px;
}

.compose-body .form-group:last-child {
  flex: 1;
}

.compose-body input.form-control {
  font-size: 14px;
  padding: 0 0 12px;
  border-radius: 0;
  border-width: 0 0 1px;
  border-bottom-color: #dde1ed;
  color: #212329;
}

.compose-body input.form-control::placeholder {
  color: #a1a8bb;
  text-shadow: none;
}

.compose-body input.form-control:focus {
  box-shadow: none;
}

.compose-body textarea.form-control {
  padding: 0;
  font-size: 14px;
  border-radius: 0;
  border-width: 0;
  color: #212329;
  resize: none;
  height: 100%;
}

.compose-body textarea.form-control:focus {
  box-shadow: none;
}

.compose-footer {
  background-color: #fff;
  border-top: 1px solid #f0f2f7;
  padding: 12px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.compose-footer .btn-icontext {
  padding-left: 15px;
  padding-right: 15px;
}

.compose-footer .btn-icontext i {
  font-size: 16px;
}</style>
