<template>
  <div class="sidebar">
    <div class="sidebar-header">
      <img alt="image"
        src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/87315075-d3a6-4da7-9261-799af583a5f7/3e8475b4-c786-4069-bdc8-cabac908f3e4?org_if_sml=1&h=200"
        class="sidebar-logo" />
    </div><!-- sidebar-header -->
    <div class="sidebar-body">
      <nav class="nav flex-column mb-5">
        <a @click="selectPage('Caixa de Entrada')" :class="{'nav-link': true, 'active': page === 'Caixa de Entrada'}"><i class="ri-inbox-line"></i> <span>Caixa de
            Entrada</span> <small>38</small></a>
        <a @click="selectPage('Enviados')" :class="{'nav-link': true, 'active': page === 'Enviados'}"><i class="ri-send-plane-2-line"></i> <span>Enviados</span></a>
        <!--<a class="nav-link"><i class="ri-draft-line"></i> <span>Rascunhos</span> <small>5</small></a>
        <a class="nav-link"><i class="ri-spam-2-line"></i> <span>Spam</span></a>
        <a class="nav-link"><i class="ri-delete-bin-line"></i> <span>Lixeira</span></a>-->
      </nav>

      <!--<label class="sidebar-label">Labels</label>
      <nav class="nav flex-column mb-5">
        <a href="" class="nav-link"><i class="ri-price-tag-line"></i> <span>Promoções</span></a>
        <a href="" class="nav-link"><i class="ri-price-tag-line"></i> <span>Social</span></a>
        <a href="" class="nav-link"><i class="ri-price-tag-line"></i> <span>Novidades</span></a>
      </nav>-->
    </div><!-- sidebar-body -->
  </div><!-- sidebar -->
</template>

<script setup>
import { defineEmits, ref } from 'vue';

const emit = defineEmits();

const page = ref("Caixa de Entrada")

const selectPage = (Page) => {
  page.value = Page
  emit('pageSelected', Page);
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 767.98px) {
  .sidebar-show {
    overflow: hidden;
  }

  .sidebar-show .sidebar {
    left: 0;
  }

  .sidebar-show .mailbox {
    transform: translateX(230px);
  }

  .sidebar-show .backdrop {
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .sidebar-hide .sidebar {
    left: -230px;
  }

  .sidebar-hide .mailbox {
    left: 0;
  }

  .sidebar-hide .mailcontent {
    width: 100vw;
  }
}

@media (min-width: 1200px) {
  .sidebar-hide .mailcontent {
    left: 390px;
    width: auto;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  left: -230px;
  width: 230px;
  height: 100vh;
  background-color: #dc0f37;
  transition: all 0.3s;
  z-index: 1000;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar {
    transition: none;
  }
}

@media (min-width: 768px) {
  .sidebar {
    left: 0;
  }
}

.sidebar-header {
  height: 80px;
  display: flex;
  align-items: center;
  padding: 4px 25px 0;
}

.sidebar-logo {
  width: 180px;
}

.sidebar-body {
  padding: 14px 25px 25px;
  position: relative;
}

.sidebar-body .nav-link {
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
}

.sidebar-body .nav-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  right: -10px;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.16);
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar-body .nav-link::before {
    transition: none;
  }
}

.sidebar-body .nav-link:hover,
.sidebar-body .nav-link:focus {
  color: #fff;
}

.sidebar-body .nav-link:hover::before,
.sidebar-body .nav-link:hover::before,
.sidebar-body .nav-link:focus::before,
.sidebar-body .nav-link:focus::before {
  visibility: visible;
  opacity: .6;
}

.sidebar-body .nav-link.active {
  color: #ff9900;
}

.sidebar-body .nav-link.active::before {
  opacity: 1;
  visibility: visible;
  background-color: #fff;
}

.sidebar-body .nav-link.active:hover::before,
.sidebar-body .nav-link.active:focus::before {
  opacity: 1;
}

.sidebar-body .nav-link i {
  font-size: 20px;
  font-weight: 400;
  position: relative;
}

.sidebar-body .nav-link span {
  position: relative;
  display: block;
  margin-left: 10px;
}

.sidebar-body .nav-link+.nav-link {
  margin-top: 1px;
}

.sidebar-body .nav-link small,
.sidebar-body .nav-link .small {
  margin-left: auto;
  font-weight: 400;
  font-size: 13px;
  position: relative;
  opacity: .7;
}

.sidebar-label {
  display: block;
  font-size: 11px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  letter-spacing: .5px;
  margin-bottom: 10px;
}</style>
