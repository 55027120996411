<script setup>

import { ref, onMounted } from 'vue';
// @ is an alias to /src
const axios = require('axios');
import SidebarApp from '@/components/SidebarApp.vue'
import MailBox from '@/components/MailBox.vue'
import MailContent from '@/components/MailContent.vue'
import MenuCompose from '@/components/MenuCompose.vue'
const emailsData = ref(null);
const responseData = ref('');
onMounted(async () => {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'https://api-growth.flashvolve.com.br/emails',
    headers: {}
  };

  try {
    responseData.value = await axios.request(config);
    emailsData.value = responseData.value.data.fetchedData.filter(email => email.from !== "aline@solutionsgrape.com");
    console.table(emailsData)
    // Ordena os e-mails pela data, do mais recente para o mais antigo
    emailsData.value.sort((a, b) => new Date(b.date) - new Date(a.date));
  } catch (error) {
    console.log(error);
  }
});

const selectedMail = ref('');

const handlePageSelected = (content) => {
  if (content == 'Enviados') {
    const botEmails = responseData.value.data.fetchedData.filter(email => email.from === "aline@solutionsgrape.com");
    emailsData.value = botEmails;

    // Ordena os e-mails pela data, do mais recente para o mais antigo
    emailsData.value.sort((a, b) => new Date(b.date) - new Date(a.date));
  }
  if (content == 'Caixa de Entrada') {
    emailsData.value = responseData.value.data.fetchedData.filter(email => email.from !== "aline@solutionsgrape.com");
    // Ordena os e-mails pela data, do mais recente para o mais antigo
    emailsData.value.sort((a, b) => new Date(b.date) - new Date(a.date));
  }
};


const handleMailSelected = (content) => {
  selectedMail.value = content;
};
</script>
<template>
  <SidebarApp @pageSelected="handlePageSelected" />
  <MailBox :mails="emailsData" @mailSelected="handleMailSelected" />
  <MailContent :content="selectedMail" />
  <MenuCompose />
</template>


