<template>
  <div id="mailContent" class="mailcontent">
    <div v-if="content" class="mailcontent-subject">
      <!--<nav class="nav">
        <a id="mailBack" href="#" class="nav-link"><i class="ri-arrow-left-line"></i></a>
        <a href="#" class="nav-link" data-bs-toggle="tooltip" title="Archive" data-bs-placement="left"><i
            class="ri-archive-drawer-line"></i></a>
        <a href="#" class="nav-link" data-bs-toggle="tooltip" title="Report Spam" data-bs-placement="left"><i
            class="ri-spam-2-line"></i></a>
        <a href="#" class="nav-link" data-bs-toggle="tooltip" title="Labels" data-bs-placement="left"><i
            class="ri-price-tag-line"></i></a>
        <a href="#" class="nav-link" data-bs-toggle="tooltip" title="Delete" data-bs-placement="left"><i
            class="ri-delete-bin-5-line"></i></a>
        <a href="#" class="nav-link" data-bs-toggle="tooltip" title="More" data-bs-placement="left"><i
            class="ri-more-2-fill"></i></a>
      </nav>-->
      <h4>{{ content.subject }}</h4>
    </div><!-- mailcontent-subject -->
    <div v-if="content" class="mailcontent-group">
      <div class="mailcontent-item">
        <div class="mailcontent-header">
          <div class="mail-avatar"><img class="mail-avatar" src="assets/img/img1.jpg" alt=""></div>
          <div class="mailcontent-sender">
            <h6>{{ content.to }} <span>&lt;{{ content.to }}&gt;</span></h6>
            <p>para: {{ content.from }}</p>
          </div><!-- mail-sender -->
          <div class="mailcontent-time"> {{ formatTime(content.date) }}</div>
          <!--<nav class="nav">
            <a href="#" class="nav-link" data-bs-toggle="tooltip" title="Not Starred"><i class="ri-star-line"></i></a>
            <a href="#" class="nav-link" data-bs-toggle="tooltip" title="Reply"><i class="ri-reply-line"></i></a>
            <a href="#" class="nav-link" data-bs-toggle="tooltip" title="More"><i class="ri-more-2-fill"></i></a>
          </nav>-->
        </div><!-- mailcontent-header -->
        <div class="mailcontent-body">
          <div v-html="content.content"></div>
          
          <hr>
          <div v-if="content.attachment">
          <label class="mailcontent-label">2 Attachments</label>

          <div class="attachment-group">
            <div class="attachment-item">
              <div class="attachment-icon text-primary"><i class="ri-file-zip-line"></i></div>
              <div class="attachment-body">
                <h6><a href="#">MarketingAssets.zip</a></h6>
                <span>812 KB</span>
              </div>
            </div><!-- attachment-item -->
          </div>
            <div class="attachment-item">
              <div class="attachment-icon text-success"><i class="ri-file-excel-line"></i></div>
              <div class="attachment-body">
                <h6><a href="#">MarketingReports.xls</a></h6>
                <span>365 KB</span>
              </div>
            </div><!-- attachment-item -->
          </div><!-- attachment-group -->
        </div><!-- mailcontent-body -->
      </div><!-- mailcontent-item -->
    </div><!-- mailcontent-group -->
    <!--<div v-if="content" class="mailcontent-footer">
      <a href="#" class="btn btn-secondary btn-icontext"><i class="ri-reply-line me-1"></i> Responder</a>
      <a href="" class="btn btn-secondary btn-icontext"><i class="ri-share-forward-line me-1"></i> Encaminhar</a>
    </div>--><!-- mailcontent-footer -->

    <div v-else class="mailcontent-placeholder">
      <i class="ri-mail-send-line"></i>
      <h5>No email has been selected</h5>
    </div><!-- mailcontent-placeholder -->
  </div><!-- mailcontent -->
</template>

<script setup>
import { defineProps } from 'vue';

const { content } = defineProps(['content']);

const formatTime = (dateString) => {
  const date = new Date(dateString);
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mailcontent-show .mailbox {
  transform: translateX(-100%);
}

.mailcontent-show .mailcontent {
  left: 0;
}

@media (min-width: 768px) {
  .mailcontent-show .mailcontent {
    left: 230px;
  }
}

@media (min-width: 768px) {
  .mailcontent-show.sidebar-hide .mailcontent {
    left: 0;
  }
}

.mailcontent {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100vw;
  height: 100vh;
  background-color: #f5f6fa;
  transition: all 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .mailcontent {
    transition: none;
  }
}

@media (min-width: 768px) {
  .mailcontent {
    width: calc(100vw - 230px);
  }
}

@media (min-width: 1200px) {
  .mailcontent {
    width: auto;
    display: block;
    left: 620px;
    right: 0;
  }
}

.mailcontent-item {
  position: relative;
  padding: 0 15px;
}

@media (min-width: 768px) {
  .mailcontent-item {
    padding: 0 25px;
  }
}

@media (max-width: 767.98px) {
  .mailcontent-item:first-child .mailcontent-header {
    padding-top: 0;
  }
}

.mailcontent-subject {
  padding: 10px;
}

@media (min-width: 768px) {
  .mailcontent-subject {
    padding: 20px;
  }
}

@media (min-width: 1200px) {
  .mailcontent-subject {
    display: flex;
    flex-direction: row-reverse;
    padding: 25px;
  }
}

.mailcontent-subject h4,
.mailcontent-subject .h4 {
  flex: 1;
  color: #212329;
  text-indent: -1px;
  margin-top: 8px;
  margin-bottom: 0;
  line-height: 1.4;
  padding-left: 5px;
}

@media (min-width: 1200px) {

  .mailcontent-subject h4,
  .mailcontent-subject .h4 {
    margin-top: 0;
    margin-right: 25px;
    padding-left: 0;
  }
}

.mailcontent-subject .nav {
  flex-shrink: 0;
  margin-top: 2.5px;
  margin-left: auto;
}

.mailcontent-subject .nav-link:first-child {
  margin-right: auto;
}

@media (min-width: 1200px) {
  .mailcontent-subject .nav-link:first-child {
    display: none;
  }
}

.mailcontent-subject .nav-link i {
  font-size: 20px;
}

.mailcontent-header {
  padding: 15px 0;
  display: flex;
  align-items: center;
}

.mailcontent-header .mail-avatar {
  width: 32px;
  height: 32px;
}

@media (min-width: 768px) {
  .mailcontent-header .mail-avatar {
    width: 38px;
    height: 38px;
  }
}

.mailcontent-header .nav {
  padding-left: 5px;
  margin-right: -5px;
}

@media (min-width: 768px) {
  .mailcontent-header .nav {
    padding-left: 10px;
    margin-right: 0;
  }
}

.mailcontent-header .nav-link i {
  font-size: 18px;
}

.mailcontent-subject .nav,
.mailcontent-header .nav {
  position: relative;
}

.mailcontent-subject .nav-link,
.mailcontent-header .nav-link {
  padding: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  color: #6b748f;
}

@media (min-width: 768px) {

  .mailcontent-subject .nav-link+.nav-link,
  .mailcontent-header .nav-link+.nav-link {
    margin-left: 2px;
  }
}

.mailcontent-subject .nav-link:hover,
.mailcontent-subject .nav-link:focus,
.mailcontent-header .nav-link:hover,
.mailcontent-header .nav-link:focus {
  background-color: rgba(221, 225, 237, 0.6);
}

.mailcontent-subject .nav-link:last-child,
.mailcontent-header .nav-link:last-child {
  width: 16px;
}

.mailcontent-sender {
  flex: 1;
  padding-left: 8px;
}

@media (min-width: 768px) {
  .mailcontent-sender {
    padding-left: 8px;
  }
}

.mailcontent-sender h6,
.mailcontent-sender .h6 {
  font-size: 14px;
  color: #212329;
  margin-bottom: 0;
}

@media (min-width: 768px) {

  .mailcontent-sender h6,
  .mailcontent-sender .h6 {
    margin-bottom: 2px;
  }
}

.mailcontent-sender h6 span,
.mailcontent-sender .h6 span {
  color: #a1a8bb;
  font-weight: 400;
  font-size: 13px;
  display: none;
}

@media (min-width: 768px) {

  .mailcontent-sender h6 span,
  .mailcontent-sender .h6 span {
    display: inline-block;
  }
}

.mailcontent-sender p {
  margin-bottom: 0;
  font-size: 12px;
}

@media (min-width: 768px) {
  .mailcontent-sender p {
    font-size: 13px;
  }
}

.mailcontent-time {
  font-size: 11px;
  font-weight: 400;
  color: #a1a8bb;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .mailcontent-time {
    font-size: 12px;
  }
}

.mailcontent-time span {
  display: none;
}

@media (min-width: 768px) {
  .mailcontent-time span {
    display: inline-block;
  }
}

.mailcontent-body {
  background-color: #fff;
  padding: 15px 20px 20px;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(221, 225, 237, 0.25), 0 2px 2px rgba(221, 225, 237, 0.2), 0 4px 4px rgba(221, 225, 237, 0.15), 0 8px 8px rgba(221, 225, 237, 0.1), 0 16px 16px rgba(221, 225, 237, 0.05);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.7;
  color: #6b748f;
}

@media (min-width: 768px) {
  .mailcontent-body {
    margin: 10px 0;
    padding: 20px 25px 25px;
  }
}

.mailcontent-body hr {
  background-color: #a1a8bb;
  margin-top: 25px;
  margin-bottom: 25px;
}

.mailcontent-label {
  display: block;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: #a1a8bb;
  letter-spacing: 1px;
  margin-bottom: 15px;
}

.attachment-group {
  position: relative;
}

@media (min-width: 768px) {
  .attachment-group {
    display: flex;
  }
}

.attachment-item {
  background-color: rgba(240, 242, 247, 0.6);
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .attachment-item {
    min-width: 240px;
  }
}

.attachment-item+.attachment-item {
  margin-top: 10px;
}

@media (min-width: 768px) {
  .attachment-item+.attachment-item {
    margin-top: 0;
    margin-left: 10px;
  }
}

.attachment-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: inherit;
  box-shadow: 0 1px 1px rgba(221, 225, 237, 0.25), 0 2px 2px rgba(221, 225, 237, 0.2), 0 4px 4px rgba(221, 225, 237, 0.15), 0 8px 8px rgba(221, 225, 237, 0.1), 0 16px 16px rgba(221, 225, 237, 0.05);
}

@media (min-width: 992px) {
  .attachment-icon {
    width: 50px;
    height: 50px;
  }
}

.attachment-icon i {
  font-size: 28px;
  line-height: 1;
}

.attachment-body {
  flex: 1;
  padding-left: 10px;
}

.attachment-body h6,
.attachment-body .h6 {
  margin-bottom: 2px;
  font-size: 14px;
  color: #212329;
}

.attachment-body a {
  color: inherit;
  text-decoration: none;
}

.attachment-body a:hover,
.attachment-body a:focus {
  color: #dc0f37;
}

.attachment-body span {
  display: block;
  font-size: 12px;
  color: #a1a8bb;
}

.mailcontent-footer {
  padding: 15px;
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  .mailcontent-footer {
    padding: 25px;
  }
}

.mailcontent-footer .btn {
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
}

.mailcontent-footer .btn+.btn {
  margin-left: 10px;
}

.card-mail {
  border-color: #7695f5;
  background-color: rgba(118, 149, 245, 0.08);
  border-width: 1.5px;
  border-radius: 0;
}

.card-mail .card-body {
  padding: 20px 25px;
  font-size: 14px;
}

.card-mail .card-title {
  color: #212329;
  margin-bottom: 12px;
}

.card-mail ul {
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 15px;
}

.mailcontent-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mailcontent-placeholder i {
  font-size: 128px;
  line-height: 1;
  margin-bottom: 10px;
  color: #a1a8bb;
  opacity: .6;
}

.mailcontent-placeholder h5,
.mailcontent-placeholder .h5 {
  font-weight: 400;
  color: #a1a8bb;
  margin-bottom: 0;
}</style>
